import { useEffect, useMemo, useRef, useState } from "react";
import { PostHogFeature, useFeatureFlagVariantKey, usePostHog } from "posthog-js/react";
import {
	Accent,
	Accordian,
	Banner,
	BannerCard,
	Button,
	CardList,
	Carosel,
	CaroselItem,
	Col,
	FaIcon,
	H1,
	H2,
	Hero,
	ImagePosition,
	LargeCard,
	P,
	PageLayout,
	PricingGrid,
	Ribon,
	Row,
	Schedule,
	Section,
	Spacer,
	VideoCard,
	VideoItem,
	VideoList,
	ViewTransition
} from "@sage/shared";
import "./HomePage.scss";

const pageToColor = {
	"CIMs": "red",
	"Teasers": "teal",
	"Presentations": "yellow",
	"Financial Analysis": "green",
	"Charts & Graphs": "purple",
	"Information Requests": "blue",
	"Data Room Analysis": "green"
};

export function HomePage() {
	const [activeCaroselPage, setActiveCaroselPage] = useState("CIMs");
	const [videoBg, setVideoBg] = useState(pageToColor["CIMs"]);
	const intervalRef = useRef(null);
	const heroVariant = useFeatureFlagVariantKey("hero-image-20240401");
	const posthog = usePostHog();

	const heroImage = useMemo(() => {
		switch (heroVariant) {
			case "variant-2":
				return "https://cdn.sageai.dev/website/assets/hero-2-fix.png";
			case "variant-3":
				return "https://cdn.sageai.dev/website/assets/hero-3.png";
			case "variant-4":
				return "https://cdn.sageai.dev/website/assets/hero-4.png";
			case "variant-5":
				return "https://cdn.sageai.dev/website/assets/hero-5.png";
			case "variant-6":
				return "https://cdn.sageai.dev/website/assets/hero-6.png";
			case "control":
				return "https://cdn.sageai.dev/website/assets/hero-person-2-transparent.png";
			default:
				return "";
		}
	}, [heroVariant]);

	function updateActiveCaroselPage(page: string) {
		clearInterval(intervalRef.current);
		setActiveCaroselPage(page);
		setVideoBg(pageToColor[page]);
	}

	function loopCarosel() {
		let idx = 0;
		let ids = [
			"CIMs",
			"Teasers",
			"Presentations",
			"Financial Analysis",
			"Charts & Graphs",
			"Information Requests",
			"Data Room Analysis"
		];
		return setInterval(() => {
			if (idx < ids.length - 1) {
				idx++;
			} else {
				idx = 0;
			}
			setActiveCaroselPage(ids[idx]);
			setVideoBg(pageToColor[ids[idx]]);
		}, 10000);
	}

	useEffect(() => {
		document.title = "Sage AI";
		intervalRef.current = loopCarosel();
		return () => {
			try {
				clearInterval(intervalRef.current);
			} catch (e) {}
		};
	}, []);

	useEffect(() => {
		console.log(posthog);
	}, [posthog]);

	posthog.featureFlags.override({ "headline-test-20240401": "variant-1" });

	if (heroImage) {
		return (
			<section className="__sage-home-page-container">
				<PageLayout size="wide">
					<Hero image={heroImage}>
						<PostHogFeature
							flag="kicker-20240104"
							match="control"
						>
							<p style={{ fontSize: "1rem" }}>AI Deal Intelligence for Investment Banks</p>
						</PostHogFeature>
						<PostHogFeature
							flag="kicker-20240104"
							match="variant-2"
						>
							<p style={{ fontSize: "1rem" }}>AI for Investment Banks</p>
						</PostHogFeature>
						<PostHogFeature
							flag="kicker-20240104"
							match="variant-3"
						>
							<p style={{ fontSize: "1rem" }}>Investment Banking AI Platform</p>
						</PostHogFeature>
						<PostHogFeature
							flag="kicker-20240104"
							match="variant-4"
						>
							<p style={{ fontSize: "1rem" }}>Specialized AI for Investment Banks</p>
						</PostHogFeature>
						<PostHogFeature
							flag="kicker-20240104"
							match="variant-5"
						>
							<p style={{ fontSize: "1rem" }}>AI Powered Investment Banking Platform</p>
						</PostHogFeature>
						<PostHogFeature
							flag="kicker-20240104"
							match="variant-6"
						>
							<p style={{ fontSize: "1rem" }}>Investment Banking AI Tools</p>
						</PostHogFeature>
						<PostHogFeature
							flag="headline-test-20240401"
							match="variant-1"
						>
							<span>
								In a business where <strong>every detail</strong> matters,
								<br />
								manual work shouldn't slow you down.
							</span>
							<H2 light>
								Sage AI transforms deal execution with AI that's as reliable as your best
								<br />
								analyst, as creative as your top MD, and faster than both combined.
								<br />
								The average team using Sage AI saves 1+ hour each day.
							</H2>
						</PostHogFeature>
						<PostHogFeature
							flag="headline-test-20240401"
							match="variant-2"
						>
							<span>
								Drive strategic <strong>deal outcomes.</strong>
								<br />
								Let <Accent>Sage AI</Accent> do the heavy lifting.
							</span>

							<H2 light>
								Transform hours of manual work into minutes. Sage's AI platform
								<br />
								handles the analytical complexity, so you can dedicate your time to
								<br />
								what truly matters: crafting deals that maximize client value.
							</H2>
						</PostHogFeature>
						<PostHogFeature
							flag="headline-test-20240401"
							match="control"
						>
							<span>
								Focus on structuring <strong>winning deals.</strong>
								<br />
								Let <Accent>Sage AI</Accent> handle the rest.
							</span>

							<H2 light>
								Stop spending hours on manual tasks. Sage's AI-powered platform
								<br />
								streamlines your deal process, giving you more time to focus on
								<br />
								what matters: delivering exceptional outcomes for your clients.
							</H2>
						</PostHogFeature>
						<Row
							gap={"1rem"}
							horizontalAlign="left"
						>
							<Button
								variant={"dark"}
								href="#examples"
							>
								<Row
									verticalAlign="center"
									horizontalAlign="center"
								>
									See Examples
									<FaIcon
										icon={"video"}
										color={"#f7a501"}
										padding="0"
										minWidth="0"
										paddingInline="0.5rem"
										hideBg
									/>
								</Row>
							</Button>
							<Button
								variant={"blue"}
								href="#get-started"
							>
								<Row
									verticalAlign="center"
									horizontalAlign="center"
								>
									Try for free
									<FaIcon
										icon={"star"}
										color={"#FFFFFF"}
										padding="0"
										minWidth="0"
										paddingInline="0.5rem"
										hideBg
									/>
								</Row>
							</Button>
						</Row>
					</Hero>
				</PageLayout>
				<PageLayout size="wide">
					<Col
						horizontalAlign="center"
						width="100%"
						gap="2rem"
					>
						<Col
							gap="2.5rem"
							horizontalAlign="center"
							width={"100%"}
						>
							<Ribon>
								<FaIcon
									icon={"file-chart-column"}
									size="2x"
									color={"#a80300"}
									animation={"fa-bounce"}
									active={activeCaroselPage === "CIMs"}
									onClick={() => updateActiveCaroselPage("CIMs")}
								>
									CIMs/CIPs
								</FaIcon>
								<FaIcon
									icon={"memo"}
									size="2x"
									color={"#30abc6"}
									animation={"fa-bounce"}
									active={activeCaroselPage === "Teasers"}
									onClick={() => updateActiveCaroselPage("Teasers")}
								>
									Teasers
								</FaIcon>
								<FaIcon
									icon={"screen-users"}
									size="2x"
									color={"#f7a501"}
									animation={"fa-bounce"}
									active={activeCaroselPage === "Presentations"}
									onClick={() => updateActiveCaroselPage("Presentations")}
								>
									Presentations
								</FaIcon>
								<FaIcon
									icon={"file-spreadsheet"}
									size="2x"
									color={"#00a857"}
									animation={"fa-bounce"}
									active={activeCaroselPage === "Financial Analysis"}
									onClick={() => updateActiveCaroselPage("Financial Analysis")}
								>
									Financial Analysis
								</FaIcon>
								<FaIcon
									icon={"chart-pie-simple-circle-dollar"}
									size="2x"
									color={"#5700a8"}
									animation={"fa-bounce"}
									active={activeCaroselPage === "Charts & Graphs"}
									onClick={() => updateActiveCaroselPage("Charts & Graphs")}
								>
									Charts & Graphs
								</FaIcon>
								<FaIcon
									icon={"ballot-check"}
									size="2x"
									color={"#0051a8"}
									animation={"fa-bounce"}
									active={activeCaroselPage === "Information Requests"}
									onClick={() => updateActiveCaroselPage("Information Requests")}
								>
									Information Requests
								</FaIcon>
								<FaIcon
									icon={"folder-tree"}
									size="2x"
									color={"#00a857"}
									animation={"fa-bounce"}
									active={activeCaroselPage === "Data Room Analysis"}
									onClick={() => updateActiveCaroselPage("Data Room Analysis")}
								>
									Data Room Analysis
								</FaIcon>
							</Ribon>
							<Carosel activePage={activeCaroselPage}>
								<CaroselItem
									id="CIMs"
									backgroundColor={"#e3adac"}
								>
									<Row
										verticalAlign="top"
										height="100%"
										gap={"1rem"}
									>
										<div className="__sage-home-page-image">
											<ViewTransition full>
												<img src={"https://cdn.sageai.dev/website/assets/home-page-cim-ts-3.avif"} />
											</ViewTransition>
										</div>
										<div className="__sage-home-page-card-text">
											<Col gap={"2rem"}>
												<Col gap={"0.5rem"}>
													<H1>Create CIM/CIP Drafts In Minutes </H1>
												</Col>
												<Row
													verticalAlign="center"
													wrap={false}
													gap={"1rem"}
												>
													<ViewTransition>
														<FaIcon
															icon={"person-running-fast"}
															size="2x"
															color={"#1d1f27"}
															active={true}
															paddingTop={"1rem"}
															paddingBottom={"1rem"}
															width={"5rem"}
															hideBg
														/>
													</ViewTransition>
													<P>
														Build CIMs and CIPs drafts in minutes using an intuitive AI user interface connected
														directly to the data room.
													</P>
												</Row>
												<Row
													verticalAlign="center"
													wrap={false}
													gap={"1rem"}
												>
													<ViewTransition>
														<FaIcon
															icon={"chart-waterfall"}
															size="2x"
															color={"#1d1f27"}
															active={true}
															paddingTop={"1rem"}
															paddingBottom={"1rem"}
															width={"5rem"}
															hideBg
														/>
													</ViewTransition>
													<P>
														Pick your slide elements and let AI fill them with data room information and charts
														that fit your firm’s exact output.
													</P>
												</Row>
											</Col>
										</div>
									</Row>
								</CaroselItem>
								<CaroselItem
									id="Teasers"
									backgroundColor={"#c0e5ee"}
								>
									<Row
										verticalAlign="top"
										height="100%"
										gap={"1rem"}
									>
										<div className="__sage-home-page-image">
											<ViewTransition full>
												<img src={"https://cdn.sageai.dev/website/assets/home-page-teaser.avif"} />
											</ViewTransition>
										</div>
										<div className="__sage-home-page-card-text">
											<Col gap={"2rem"}>
												<H1>Create Comprehensive Teasers</H1>
												<Row
													verticalAlign="center"
													wrap={false}
													gap={"1rem"}
												>
													<ViewTransition>
														<FaIcon
															icon={"business-time"}
															size="2x"
															color={"#1d1f27"}
															active={true}
															paddingTop={"1rem"}
															paddingBottom={"1rem"}
															width={"5rem"}
															hideBg
														/>
													</ViewTransition>
													<P>
														Create anonymized teasers in minutes by pulling data straight from your data room
														and past deals.
													</P>
												</Row>
											</Col>
										</div>
									</Row>
								</CaroselItem>
								<CaroselItem
									id="Presentations"
									backgroundColor={"#fde4b2"}
								>
									<Row
										verticalAlign="top"
										height="100%"
										gap={"1rem"}
									>
										<div className="__sage-home-page-image">
											<ViewTransition full>
												<img src={"https://cdn.sageai.dev/website/assets/home-page-presentations.avif"} />
											</ViewTransition>
										</div>

										<div className="__sage-home-page-card-text">
											<Col gap={"2rem"}>
												<H1>Create Management Presentation Drafts Fast</H1>
												<Row
													verticalAlign="center"
													wrap={false}
													gap={"1rem"}
												>
													<ViewTransition>
														<FaIcon
															icon={"print"}
															size="2x"
															color={"#1d1f27"}
															active={true}
															paddingTop={"1rem"}
															paddingBottom={"1rem"}
															width={"5rem"}
															hideBg
														/>
													</ViewTransition>
													<P>Create compelling management decks in minutes using your templates.</P>
												</Row>
												<Row
													verticalAlign="center"
													wrap={false}
													gap={"1rem"}
												>
													<ViewTransition>
														<FaIcon
															icon={"chart-pie"}
															size="2x"
															color={"#1d1f27"}
															active={true}
															paddingTop={"1rem"}
															paddingBottom={"1rem"}
															width={"5rem"}
															hideBg
														/>
													</ViewTransition>
													<P>Add charts and analysis directly from your data room to tell your client's story.</P>
												</Row>
											</Col>
										</div>
									</Row>
								</CaroselItem>
								<CaroselItem
									id="Financial Analysis"
									backgroundColor={"#ace3c8"}
								>
									<Row
										verticalAlign="top"
										height="100%"
										gap={"1rem"}
									>
										<div className="__sage-home-page-image">
											<ViewTransition full>
												<img src={"https://cdn.sageai.dev/website/assets/home-page-financial-analysis.avif"} />
											</ViewTransition>
										</div>
										<div className="__sage-home-page-card-text">
											<Col gap={"2rem"}>
												<H1>Perform Complex Financial Analysis</H1>
												<Row
													verticalAlign="center"
													wrap={false}
													gap={"1rem"}
												>
													<ViewTransition>
														<FaIcon
															icon={"layer-group"}
															size="2x"
															color={"#1d1f27"}
															active={true}
															paddingTop={"1rem"}
															paddingBottom={"1rem"}
															width={"5rem"}
															hideBg
														/>
													</ViewTransition>
													<P>Turn messy financial data into clear insights and charts in seconds.</P>
												</Row>
												<Row
													verticalAlign="center"
													wrap={false}
													gap={"1rem"}
												>
													<ViewTransition>
														<FaIcon
															icon={"table-layout"}
															size="2x"
															color={"#1d1f27"}
															active={true}
															paddingTop={"1rem"}
															paddingBottom={"1rem"}
															width={"5rem"}
															hideBg
														/>
													</ViewTransition>
													<P>
														Get instant analysis for CIMs, models, and management decks without touching Excel.
													</P>
												</Row>
											</Col>
										</div>
									</Row>
								</CaroselItem>
								<CaroselItem
									id="Charts & Graphs"
									backgroundColor={"#c8ace3"}
								>
									<Row
										verticalAlign="top"
										height="100%"
										gap={"1rem"}
									>
										<div className="__sage-home-page-image">
											<ViewTransition full>
												<img src={"https://cdn.sageai.dev/website/assets/home-page-charts-graphs.avif"} />
											</ViewTransition>
										</div>
										<div className="__sage-home-page-card-text">
											<Col gap={"2rem"}>
												<H1>Create Complex Charts & Graphs</H1>
												<Row
													verticalAlign="center"
													wrap={false}
													gap={"1rem"}
												>
													<ViewTransition>
														<FaIcon
															icon={"chart-line-up"}
															size="2x"
															color={"#1d1f27"}
															active={true}
															paddingTop={"1rem"}
															paddingBottom={"1rem"}
															width={"5rem"}
															hideBg
														/>
													</ViewTransition>
													<P>
														Get instant answers to buyer questions with charts and data straight from your data
														room.
													</P>
												</Row>
												<Row
													verticalAlign="center"
													wrap={false}
													gap={"1rem"}
												>
													<ViewTransition>
														<FaIcon
															icon={"chart-network"}
															size="2x"
															color={"#1d1f27"}
															active={true}
															paddingTop={"1rem"}
															paddingBottom={"1rem"}
															width={"5rem"}
															hideBg
														/>
													</ViewTransition>
													<P>
														Just ask in plain English - 'Show me revenue by customer' - and Sage creates the
														perfect visualization.
													</P>
												</Row>
											</Col>
										</div>
									</Row>
								</CaroselItem>
								<CaroselItem
									id="Information Requests"
									backgroundColor={"#acc6e3"}
								>
									<Row
										verticalAlign="top"
										height="100%"
										gap={"1rem"}
									>
										<div className="__sage-home-page-image">
											<ViewTransition full>
												<img src={"https://cdn.sageai.dev/website/assets/home-page-info-request.avif"} />
											</ViewTransition>
										</div>
										<div className="__sage-home-page-card-text">
											<Col gap={"2rem"}>
												<H1>Build precise information requests in minutes.</H1>
												<P>Build your info request list in minutes, not hours. </P>
												<Row
													verticalAlign="center"
													wrap={false}
													gap={"1rem"}
												>
													<ViewTransition>
														<FaIcon
															icon={"list-check"}
															size="2x"
															color={"#1d1f27"}
															active={true}
															paddingTop={"1rem"}
															paddingBottom={"1rem"}
															width={"5rem"}
															hideBg
														/>
													</ViewTransition>
													<P>
														Sage removes irrelevant items and adds important deal-specific requests
														automatically.
													</P>
												</Row>
											</Col>
										</div>
									</Row>
								</CaroselItem>
								<CaroselItem
									id="Data Room Analysis"
									backgroundColor={"#ace3c8"}
								>
									<Row
										verticalAlign="top"
										height="100%"
										gap={"1rem"}
									>
										<div className="__sage-home-page-image">
											<ViewTransition full>
												<img src={"https://cdn.sageai.dev/website/assets/home-page-data-room-ts.avif"} />
											</ViewTransition>
										</div>
										<div className="__sage-home-page-card-text">
											<Col gap={"2rem"}>
												<H1>Find Any Information in the Data Room In Seconds</H1>
												<Row
													verticalAlign="center"
													wrap={false}
													gap={"1rem"}
												>
													<ViewTransition>
														<FaIcon
															icon={"person-running-fast"}
															size="2x"
															color={"#1d1f27"}
															active={true}
															paddingTop={"1rem"}
															paddingBottom={"1rem"}
															width={"5rem"}
															hideBg
														/>
													</ViewTransition>
													<P>
														Find what you need instantly in any data room - even if you have thousands of files.{" "}
													</P>
												</Row>
												<Row
													verticalAlign="center"
													wrap={false}
													gap={"1rem"}
												>
													<ViewTransition>
														<FaIcon
															icon={"chart-waterfall"}
															size="2x"
															color={"#1d1f27"}
															active={true}
															paddingTop={"1rem"}
															paddingBottom={"1rem"}
															width={"5rem"}
															hideBg
														/>
													</ViewTransition>
													<P>You can even create things like charts, graphs, and tables.</P>
												</Row>
											</Col>
										</div>
									</Row>
								</CaroselItem>
							</Carosel>
						</Col>
					</Col>
				</PageLayout>
				<PageLayout>
					<Spacer></Spacer>
					<Row
						horizontalAlign="center"
						verticalAlign="center"
						width={"100%"}
					>
						<Col
							gap={"2rem"}
							maxWidth={"1000px"}
						>
							<H1>See How Investment Banks Are Using Sage AI.</H1>
							<H2 light>
								Watch a 5-minute demo of our AI platform automating the most time-consuming parts of deals, while
								maintaining your firm's quality standards.
							</H2>
							<VideoCard
								video={"https://sageai.dev/cdn/website/assets/sage-vsl.mp4"}
								thumbnail={"https://cdn.sageai.dev/website/assets/home-page-thumbnail-2.avif"}
								size={"medium"}
								borderColor={videoBg}
								controls
							/>
						</Col>
					</Row>
				</PageLayout>
				<PageLayout>
					<Section name="examples">
						<Row
							horizontalAlign="center"
							verticalAlign="center"
							width={"100%"}
						>
							<Col
								gap={"2rem"}
								maxWidth={"800px"}
							>
								<H1>Examples</H1>
								<H2 light>
									See exactly how <Accent>Sage AI</Accent> works by watching any of these examples.
								</H2>
								<VideoCard
									video={"https://cdn.sageai.dev/website/assets/slide-deck.mp4"}
									size={"medium"}
									borderColor={videoBg}
									autoPlay
									controls={false}
									muted
									loop
								/>
							</Col>
						</Row>
						<VideoList>
							<VideoItem
								title={"Financial Performance Analysis"}
								description={
									"Quick Tutorial: How to Generate a Financial Performance Table in Sage AI\n\nLearn how to instantly create a professional financial analysis table using Sage AI's data consolidation capabilities.\n\nThis video shows how to automatically compile and customize financial data from your uploaded documents using the Executive Summary tool, complete with proper citations - transforming what's typically an hour-long spreadsheet consolidation task into a 30-second automated process."
								}
								src={"https://cdn.sageai.dev/example-videos/2-financial-performance/final.mp4"}
								thumbnail={"https://cdn.sageai.dev/example-videos/2-financial-performance/thumbnail.png"}
								preview={"https://cdn.sageai.dev/example-videos/2-financial-performance/preview.mp4"}
							/>
							<VideoItem
								title={"Company History"}
								description={
									"Quick Tutorial: How to Generate a Company History Section in Sage AI\n\nLearn how to instantly create a professional company timeline using Sage AI's document analysis capabilities.\n\nThis video shows how to automatically compile and refine historical events from your uploaded documents using the Business Overview tool, complete with proper footnotes - transforming what's typically an hour-long research task into a 30-second automated process."
								}
								src={"https://cdn.sageai.dev/example-videos/5-company-history/final.mp4"}
								thumbnail={"https://cdn.sageai.dev/example-videos/5-company-history/thumbnail.png"}
								preview={"https://cdn.sageai.dev/example-videos/5-company-history/preview.mp4"}
							/>
							<VideoItem
								title={"Customer Segment Profiles"}
								description={
									"Quick Tutorial: How to Generate Customer Segment Profiles in Sage AI\n\nLearn how to instantly create professional customer segment analyses using Sage AI's document analysis capabilities.\n\nThis video demonstrates how to automatically generate and format detailed customer profiles from your uploaded documents using the Business Overview tool, complete with clarifying footnotes - turning what's typically an hour-long analysis task into a 30-second automated process."
								}
								src={"https://cdn.sageai.dev/example-videos/6-customer-segments/final.mp4"}
								thumbnail={"https://cdn.sageai.dev/example-videos/6-customer-segments/thumbnail.png"}
								preview={"https://cdn.sageai.dev/example-videos/6-customer-segments/preview.mp4"}
							/>
						</VideoList>
						<Col gap="1.5rem">
							<H1>More Examples?</H1>
							<H2 light>Check out our full page of examples to see more usecases.</H2>
							<Button
								variant={"dark"}
								href="/examples"
							>
								<Row
									verticalAlign="center"
									horizontalAlign="center"
								>
									Examples
									<FaIcon
										icon={"video"}
										color={"#f7a501"}
										padding="0"
										minWidth="0"
										paddingInline="0.5rem"
										hideBg
									/>
								</Row>
							</Button>
						</Col>
					</Section>
					<Section name="get-started">
						<LargeCard
							tagline="No Hastle Setup"
							title={
								<>
									From Signup to CIM Slides in <Accent>30 Minutes</Accent> or Less
								</>
							}
							subtitle={"No extensive setup or training required - just instant efficiency for your deal team. "}
							content={
								"Simply connect your data room and start getting answers in seconds. Whether it’s 20 files or 2000 files, Sage will find what you’re looking for in seconds.\n\nOur intuitive interface lets you hit the ground running. Pull key information, draft complex slides, and answer diligence questions."
							}
							image={"https://cdn.sageai.dev/website/assets/home-page-cim-ts-3.avif"}
							imagePosition={ImagePosition.Right}
							imageBorder="none"
						/>
						<PricingGrid />
					</Section>
					<Section name="calendar">
						<Schedule />
					</Section>
				</PageLayout>
				<Spacer>
					<Banner
						src={"https://cdn.sageai.dev/website/assets/home-page-banner-4.avif"}
						size={"sm"}
						center={
							<BannerCard
								variant={"center"}
								title={"Trusted by Investment Banks across 11 countries"}
								color="dark"
								content={<img src="https://cdn.sageai.dev/website/assets/globe.avif" />}
							/>
						}
					/>
				</Spacer>
				<PageLayout>
					<LargeCard
						tagline="Security"
						title={
							<>
								Safe AI with <Accent>enterprise-grade</Accent> security at every level
							</>
						}
						content={
							"Enterprise-grade encryption protects your data, models, and proprietary knowledge.\n\nDedicated infrastructure ensures complete data isolation between clients.\n\nSage AI is the only Generative AI platform purpose-built for investment banking, Sage AI offers unparalleled security for your firm’s most valuable assets - your data and your people. "
						}
						image={"https://cdn.sageai.dev/website/assets/sec-2.avif"}
						imagePosition={ImagePosition.Left}
					/>
					<Accordian
						title={"Frequently Asked Questions"}
						hideImg
						sections={[
							{
								title: "How Does Sage AI Keep Everything 100% Private?",
								id: "privacy",
								content:
									"Sage AI implements enterprise-grade security at every level to ensure your data remains completely private. All data is encrypted both in transit and at rest using AWS infrastructure, with no third-party vendors involved.\n\nOur multi-tenant architecture ensures complete data isolation between clients through mandatory team ID authentication, meaning your firm's data can never be accessed by or mixed with other clients' information. We do not train our models on client data, and any support access requires explicit permission from your team.\n\nAs a tools company rather than a models company, our business model is built around protecting your privacy while helping you work more efficiently."
							},
							{
								title: "How Do You Ensure Data Is Not Used for Training?",
								id: "training",
								content:
									"At Sage AI, we are fundamentally a tools company, not a models company. This means we have no business incentive to train on your data - our goal is to provide you with powerful tools, not to build better AI models.\n\nWe maintain strict technical controls that prevent your data from being used for training: all information is siloed by company, encrypted end-to-end, and only accessible via secure authentication tokens. Any model training would require explicit written agreements, which we do not seek or encourage.\n\nUnlike general AI companies that may train on user data to improve their models, our business is built around helping investment banks work more efficiently while maintaining absolute confidentiality of their deal information. Your data is used solely to help you complete your work - nothing more."
							},
							{
								title: "How Do You Ensure Zero Chance of Data Breaches?",
								id: "data-breaches",
								content:
									"At Sage AI, we ensure zero chance of data breaches through multiple layers of enterprise-grade security. We use AWS's secure infrastructure with end-to-end encryption, strict data isolation between clients through mandatory team ID authentication, and no third-party vendor access.\n\nAll data is encrypted both in transit and at rest using industry-standard protocols. Our engineering team, with backgrounds from pioneers of modern internet infrastructure, has built security into every aspect of our platform.\n\nWe employ active security monitoring, and regularly review our security measures. For complete transparency, we're happy to work directly with your compliance team to explain our comprehensive security protocols in detail."
							},
							{
								title: "How Do You Ensure Safe and Accurate Responses?",
								id: "accuracy",
								content:
									"At Sage AI, we ensure safe and accurate responses by only using information from your connected data sources - never external websites or generic AI knowledge. When you ask a question, Sage searches solely through your data room, internal documents, and verified company websites that you've explicitly connected.\n\nEvery response is backed by direct citations to source documents, which you can instantly verify with a single click. If information isn't available in your connected sources, Sage will tell you directly rather than making assumptions. We also maintain strict version control of all documents, so you can track exactly where each piece of information originated. This source-based approach ensures that every response is accurate, verifiable, and based purely on your firm's trusted data."
							},
							{
								title: "What Does it Cost?",
								id: "cost",
								content:
									"Our pricing starts at just $80 per user per month, making Sage AI accessible for investment banks of all sizes. Each plan is customized to your firm's specific needs, volume of deals, and number of users. To get a detailed pricing breakdown and learn about volume discounts for your team, schedule a quick 10-minute call with us today. We'll walk you through how we can tailor our platform to maximize value for your specific use case."
							},
							{
								title: "How Long Can I Try It For?",
								id: "trial",
								content:
									"Our trial periods are customized to match your team's needs and deal timeline. Rather than a fixed trial period, we work with each firm to ensure you have enough time to properly evaluate Sage AI on actual deal work. This could mean testing it on a current transaction, analyzing a past deal, or setting up templates for upcoming projects. Schedule a quick call with us to discuss your specific requirements and we'll design a trial that makes sense for your team."
							},
							{
								title: "I only do 1-5 deals per year, I wont use it that much.",
								id: "small",
								content:
									"Even with a smaller deal volume, Sage AI delivers significant value by dramatically reducing the time spent on each transaction. Whether you're working on one deal or twenty, tasks like analyzing data rooms, creating CIMs, and responding to buyer questions still consume dozens of hours per deal.\n\nOur platform helps you complete these tasks in a fraction of the time, letting you focus on high-value activities like client relationships and deal strategy. Plus, Sage AI's capabilities extend beyond just deal execution - you can use it for prospect research, pitch materials, and market analysis between transactions.\n\nMany of our users with similar deal volumes report that Sage pays for itself just by eliminating late nights and weekend work on their first deal."
							},
							{
								title: "My slides don't look like the ones Sage produces...",
								id: "slides",
								content:
									"While it's true that you'll need to format slides according to your firm's exact templates and branding, Sage AI saves you time where it matters most: gathering and organizing information.\n\nTypically, over half the time spent creating deal materials goes into finding relevant data, analyzing financials, verifying facts, and structuring content - not formatting slides. Sage AI eliminates these time-consuming tasks by instantly pulling all pertinent information from your data room, organizing it logically, and presenting it in a clear format that's easily transferable to your slides.\n\nThe platform also provides:\n\n - Accurate financial data and calculations\n\n - Ready-to-use charts and graphs\n\n - Pre-written content that can be quickly customized\n\n - Direct citations for every data point\n\n - Exportable tables and figures\n\nThis means you start with comprehensive, well-structured content instead of a blank page - then simply apply your firm's formatting standards to create polished, client-ready materials."
							}
						]}
					/>
				</PageLayout>
				<PageLayout>
					<H2>Want to learn more?</H2>
				</PageLayout>
				<Spacer>
					<CardList
						cards={[
							{
								title: "Schedule a Demo",
								image: "https://cdn.sageai.dev/website/assets/docs-athena-close-up-1.avif",
								content: "Developed from M&A processes at industry leading firms to address real-world challenges.",
								href: "#get-started",
								cta: "Schedule"
							},
							{
								title: "Examples",
								image: "https://cdn.sageai.dev/example-videos/1-business-description/thumbnail.png",
								content: "Check out some example videos of common tasks within Sage",
								href: "/examples",
								cta: "Watch"
							},
							{
								title: "Sage AI Data Security",
								content:
									"At Sage AI, data security is ensured through our three layer approach. Document Level Security, Account Level Security and System Level Security.\n\nEach tier of security & controls reinforces the others. To see exactly how this works, take a look at this page.",
								href: "/security/how-we-secure-your-data",
								image: "https://cdn.sageai.dev/website/assets/sage-ai-cloud-security-banner.avif"
							}
						]}
					/>
				</Spacer>
			</section>
		);
	}
	return <section className="__sage-home-page-container"></section>;
}
