import { useEffect } from "react";
import { Accent, Col, H2, Hero, MutliTextScroll, PageLayout, VideoCard, VideoItem, VideoList } from "@sage/shared";

export function ExamplesPage() {
	useEffect(() => {
		document.title = "Sage AI | Examples";
	}, []);

	return (
		<section className="__sage-examples-container">
			<PageLayout size="wide">
				<Col
					horizontalAlign="center"
					width="100%"
					gap="2rem"
				>
					<Hero inline>
						400+ Instant Analyses
						<MutliTextScroll
							options={[
								"Business Description",
								"Financial Performance",
								"Legal Structure",
								"Product Portfolio",
								"Company History",
								"Customer Segments",
								"Financial Statements",
								"EBITDA Bridge",
								"Cost Analysis",
								"Growth Drivers"
							]}
						/>
						<H2 light>
							See exactly how <Accent>Sage AI</Accent> works by watching any of these examples.
						</H2>
					</Hero>
				</Col>
				<VideoCard
					video={"https://cdn.sageai.dev/website/assets/slide-deck.mp4"}
					size={"medium"}
					autoPlay
					controls={false}
					muted
					loop
					borderColor="blue"
				/>

				<VideoList>
					<VideoItem
						title={"Business Description"}
						description={
							"Quick Tutorial: How to Generate a Business Description in Sage AI\n\nLearn how to instantly create a professional business description using Sage AI's document analysis capabilities.\n\nThis video demonstrates how to automatically generate and refine a business overview from your uploaded documents using the Executive Summary tool, complete with proper citations - turning what's typically a 45-minute manual task into a 30-second automated process."
						}
						src={"https://cdn.sageai.dev/example-videos/1-business-description/final.mp4"}
						thumbnail={"https://cdn.sageai.dev/example-videos/1-business-description/thumbnail.png"}
						preview={"https://cdn.sageai.dev/example-videos/1-business-description/preview.mp4"}
					/>
					<VideoItem
						title={"Financial Performance Analysis"}
						description={
							"Quick Tutorial: How to Generate a Financial Performance Table in Sage AI\n\nLearn how to instantly create a professional financial analysis table using Sage AI's data consolidation capabilities.\n\nThis video shows how to automatically compile and customize financial data from your uploaded documents using the Executive Summary tool, complete with proper citations - transforming what's typically an hour-long spreadsheet consolidation task into a 30-second automated process."
						}
						src={"https://cdn.sageai.dev/example-videos/2-financial-performance/final.mp4"}
						thumbnail={"https://cdn.sageai.dev/example-videos/2-financial-performance/thumbnail.png"}
						preview={"https://cdn.sageai.dev/example-videos/2-financial-performance/preview.mp4"}
					/>
					<VideoItem
						title={"Corporate Legal Structure"}
						description={
							"Quick Tutorial: How to Generate a Legal Structure Section in Sage AI\n\nLearn how to instantly create a professional company legal structure overview using Sage AI's document analysis capabilities.\n\nThis video demonstrates how to automatically generate, verify, and refine legal structure information from your uploaded documents using the Executive Summary tool, complete with source citations - reducing a 20-minute manual task to a 30-second automated process."
						}
						src={"https://cdn.sageai.dev/example-videos/3-legal-structure/final.mp4"}
						thumbnail={"https://cdn.sageai.dev/example-videos/3-legal-structure/thumbnail.png"}
						preview={"https://cdn.sageai.dev/example-videos/3-legal-structure/preview.mp4"}
					/>
					<VideoItem
						title={"Company History"}
						description={
							"Quick Tutorial: How to Generate a Company History Section in Sage AI\n\nLearn how to instantly create a professional company timeline using Sage AI's document analysis capabilities.\n\nThis video shows how to automatically compile and refine historical events from your uploaded documents using the Business Overview tool, complete with proper footnotes - transforming what's typically an hour-long research task into a 30-second automated process."
						}
						src={"https://cdn.sageai.dev/example-videos/5-company-history/final.mp4"}
						thumbnail={"https://cdn.sageai.dev/example-videos/5-company-history/thumbnail.png"}
						preview={"https://cdn.sageai.dev/example-videos/5-company-history/preview.mp4"}
					/>
					<VideoItem
						title={"Customer Segment Profiles"}
						description={
							"Quick Tutorial: How to Generate Customer Segment Profiles in Sage AI\n\nLearn how to instantly create professional customer segment analyses using Sage AI's document analysis capabilities.\n\nThis video demonstrates how to automatically generate and format detailed customer profiles from your uploaded documents using the Business Overview tool, complete with clarifying footnotes - turning what's typically an hour-long analysis task into a 30-second automated process."
						}
						src={"https://cdn.sageai.dev/example-videos/6-customer-segments/final.mp4"}
						thumbnail={"https://cdn.sageai.dev/example-videos/6-customer-segments/thumbnail.png"}
						preview={"https://cdn.sageai.dev/example-videos/6-customer-segments/preview.mp4"}
					/>
				</VideoList>
			</PageLayout>
		</section>
	);
}
