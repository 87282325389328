import { useEffect } from "react";
import {
	Accent,
	Banner,
	BannerCard,
	Button,
	CardList,
	Col,
	H2,
	LargeCard,
	P,
	PageLayout,
	PricingGrid,
	Row,
	Schedule,
	Section,
	VideoCard
} from "@sage/shared";

export function GetStartedPage() {
	useEffect(() => {
		document.title = "Get Started | Sage AI";
	}, []);

	return (
		<section>
			<Banner
				src={"https://cdn.sageai.dev/website/assets/get-started-banner.avif"}
				left={
					<BannerCard
						title={"Enhance Your Investment Banking Process"}
						tagline={"SAGE AI"}
						content={
							"Streamline document creation, analyze data rooms efficiently, and gain deeper insights. Start your journey with Sage AI and experience how AI can support your investment banking workflow."
						}
					/>
				}
				right={<div />}
			/>
			<PageLayout>
				<VideoCard
					video={"https://sageai.dev/cdn/website/assets/sage-vsl.mp4"}
					thumbnail={"https://cdn.sageai.dev/website/assets/home-page-thumbnail-2.avif"}
					size={"large"}
					controls
					borderColor="blue"
				/>
				<Section name="schedule">
					<Row
						horizontalAlign="center"
						width={"100%"}
					>
						<Col
							gap={"1rem"}
							maxWidth={"800px"}
						>
							<H2>
								Try <Accent>Sage AI</Accent> Today
							</H2>
							<P>
								Sage AI’s system is designed to get results.{" "}
								<Accent>
									<strong>Fast.</strong>
								</Accent>
							</P>
							<P>Our intuitive UI allows you to get client ready results in less than 30 minutes of signing up.</P>
							<P>
								No extensive set ups or training required. Just simply meet with Brendan to get a trial account created this
								afternoon and start saving time today.
							</P>
							<P></P>
							<H2>Do you want to see some examples?</H2>
							<P>Check out our page of examples to see some common use cases before you schedule a call</P>
							<Button
								variant={"blue"}
								href="/examples"
							>
								Examples
							</Button>
						</Col>
					</Row>
				</Section>
				<Section name="get-started">
					<PricingGrid />
				</Section>
				<Section name="calendar">
					<Schedule />
					<LargeCard
						tagline={"AI Powered Deal Insights"}
						title={"Unlock Critical Deal Insights with AI-Powered Analysis"}
					/>
					<CardList
						cards={[
							{
								title: "Faster Document Creation",
								image: "https://cdn.sageai.dev/website/assets/docs-athena-close-up-1.avif",
								content:
									"Generate high-quality CIMs and CIPs in a fraction of the time. Our AI-powered system streamlines your workflow, allowing you to focus on strategic decision-making.",
								href: "/solutions/deliverable-creation"
							},
							{
								title: "Data Room Analysis",
								image: "https://cdn.sageai.dev/website/assets/docs-project-files-close-up-1.avif",
								content:
									"Quickly surface key insights from vast amounts of data. Our advanced algorithms help you identify critical information and trends, enhancing your due diligence process.",
								href: "/solutions/information-retrieval"
							},
							{
								title: "Adaptive Templates",
								image: "https://cdn.sageai.dev/website/assets/templates-closeup-1.avif",
								content:
									"Leverage AI-driven templates that learn from your best work. Our system evolves with your needs, ensuring consistent quality across all your documents.",
								href: "/solutions/deliverable-creation#templates"
							},
							{
								title: "Bank-Grade Security",
								image: "https://cdn.sageai.dev/website/assets/sage-ai-cloud-security-banner.avif",
								content:
									"At Sage AI, data security is ensured through our three layer approach. Document Level Security, Account Level Security and System Level Security.",
								href: "/security/how-we-secure-your-data"
							}
						]}
					/>
				</Section>
			</PageLayout>
		</section>
	);
}
