import { ReactNode, useMemo, useState } from "react";
import { byPrefixAndName } from "@awesome.me/kit-17c50395ad/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./FaIcon.scss";

export function FaIcon({
	color,
	icon,
	size,
	animation,
	children,
	direction,
	active,
	onClick,
	paddingInline,
	paddingTop,
	paddingBottom,
	padding,
	hideBg,
	minWidth
}: {
	color?: string;
	icon: string;
	size?: string;
	animation?: string;
	children?: ReactNode;
	direction?: "row" | "column";
	active?: boolean;
	onClick?: () => void;
	paddingTop?: string;
	paddingBottom?: string;
	paddingInline?: string;
	padding?: string;
	hideBg?: boolean;
	minWidth?: string;
}) {
	const [hover, setHover] = useState<boolean>(false);

	const [hoverColor, hoverBorderColor] = useMemo(() => {
		if (color) {
			const r = parseInt(color.slice(1, 3), 16);
			const g = parseInt(color.slice(3, 5), 16);
			const b = parseInt(color.slice(5, 7), 16);
			return [`rgba(${r}, ${g}, ${b}, 0.05)`, `rgba(${r}, ${g}, ${b}, 0.2)`];
		}
	}, [color]);

	return (
		<div
			className={`__sage-fa-icon-container ${direction}`}
			onMouseOver={() => setHover(true)}
			onMouseOut={() => setHover(false)}
			onClick={() => (onClick ? onClick() : {})}
			style={{ paddingTop, paddingBottom, padding, paddingInline, minWidth }}
		>
			<FontAwesomeIcon
				icon={byPrefixAndName.fad[icon]}
				size={"2x" || size}
				color={color}
				className={`${hover && animation}`}
				fixedWidth={direction === "row"}
			/>
			{children}
			{hideBg !== true && (
				<div
					className={`bg ${active ? "active" : ""}`}
					style={{ backgroundColor: hoverColor, border: `solid 1px ${hoverBorderColor}` }}
				/>
			)}
		</div>
	);
}
