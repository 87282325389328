import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Collapsible } from "../Collapsible";
import { Dropdown } from "../Dropdown";
import { Link } from "../Link";
import "./Header.scss";

export function Header() {
	const [bg, setBg] = useState<boolean>(false);
	const [visible, setVisible] = useState<boolean>(false);
	const { pathname } = useLocation();

	function scrollListener() {
		setBg(window.scrollY > 30);
	}

	useEffect(() => {
		document.addEventListener("scroll", scrollListener);
		return () => {
			document.removeEventListener("scroll", scrollListener);
		};
	}, []);

	useEffect(() => {
		setVisible(false);
	}, [pathname]);

	if (window.innerWidth > 800) {
		return (
			<div className={`__sage-header-container ${bg ? "bg" : ""}`}>
				<div className="col">
					<div className="logo">
						<img src="https://cdn.ccm.vc/sage/logo-cropped.svg" />
						<Link href={"/"}>Sage AI</Link>
					</div>
					<Dropdown
						title={"Solutions"}
						options={[
							{
								text: "Deliverable Creation",
								href: "/solutions/deliverable-creation",
								icon: "file-chart-column",
								color: "#a80300"
							},
							{
								text: "Information Retrieval",
								href: "/solutions/information-retrieval",
								icon: "file-spreadsheet",
								color: "#00a857"
							},
							{
								text: "Investment Banking",
								href: "/solutions/investment-banking",
								icon: "user-tie",
								color: "#30abc6"
							}
						]}
					/>
					<Dropdown
						title={"Security"}
						options={[
							{
								text: "How We Secure Your Data",
								href: "/security/how-we-secure-your-data",
								icon: "shield-check",
								color: "#0051a8"
							},
							{
								text: "Security & Privacy Policy",
								href: "/security/security-and-privacy-policy",
								icon: "fingerprint",
								color: "#5700a8"
							},
							{
								text: "Governance",
								href: "/security/governance",
								icon: "building-lock",
								color: "#f7a501"
							}
						]}
					/>
					<Dropdown
						title={"Responsible AI"}
						options={[
							{
								text: "Overview",
								href: "/responsible-ai/overview",
								icon: "microchip-ai",
								color: "#b62ad9"
							},
							{
								text: "Answers You Can Trust",
								href: "/responsible-ai/answers-you-can-trust",
								icon: "message-bot",
								color: "#a80300"
							},
							{
								text: "Data Training & Privacy",
								href: "/responsible-ai/data-training-and-privacy",
								icon: "binary-lock",
								color: "#0051a8"
							},
							{
								text: "Principles & Approach",
								href: "/responsible-ai/principles-and-approach",
								icon: "brain-circuit",
								color: "#00a857"
							}
						]}
					/>
					<Dropdown
						title={"Company"}
						options={[
							{
								text: "Contact Us",
								href: "/get-started",
								icon: "building-user",
								color: "#0051a8"
							}
						]}
					/>
				</div>
				<div className="col">
					<div className="sign-up">{/* </Button variant="dark" href={"/get-started"}>Get Started</Button> */}</div>
				</div>
			</div>
		);
	} else {
		const links = {
			"/": "Sage AI",
			"/why-ai/industry-adoption": "Industry Adoption",
			"/why-ai/business-impact": "Business Impact",
			"/why-ai/increase-leverage": "Increase Leverage",
			"/solutions/deliverable-creation": "Deliverable Creation",
			"/solutions/information-retrieval": "Information Retrieval",
			"/solutions/investment-banking": "Investment Banking",
			"/solutions/private-equity": "Private Equity",
			"/solutions/equity-research": "Equity Research",
			"/security/how-we-secure-your-data": "How We Secure Your Data",
			"/security/security-and-privacy-policy": "Security & Privacy Policy",
			"/security/governance": "Governance",
			"/responsible-ai/overview": "Responsible AI Overview",
			"/responsible-ai/answers-you-can-trust": "Answers You Can Trust",
			"/responsible-ai/data-training-and-privacy": "Data Training & Privacy",
			"/responsible-ai/principles-and-approach": "Principles & Approach",
			"/about/about-us": "About Sage",
			"/about/meet-our-team": "Meet Our Team",
			"/about/contact-us": "Contact Us",
			"/get-started": "Get Started",
			"/examples": "Examples"
		};

		return (
			<div className="__sage-header-dropdown-container">
				<div
					className="active"
					onClick={() => setVisible((e) => !e)}
				>
					{links[pathname]}
					<img
						alt="img-icon"
						src={`https://cdn.ccm.vc/sage/icons/material-arrow-down.svg`}
						width={12}
					/>
				</div>
				<Collapsible visible={visible}>
					<div className="get-started">
						<Link href={"/"}>Sage AI</Link>
					</div>
					<Dropdown
						title={"Solutions"}
						size={"full"}
						position={"relative"}
						options={[
							{
								text: "Deliverable Creation",
								href: "/solutions/deliverable-creation",
								icon: "file-chart-column",
								color: "#a80300"
							},
							{
								text: "Information Retrieval",
								href: "/solutions/information-retrieval",
								icon: "file-spreadsheet",
								color: "#00a857"
							},
							{
								text: "Investment Banking",
								href: "/solutions/investment-banking",
								icon: "user-tie",
								color: "#30abc6"
							}
						]}
					/>
					<Dropdown
						title={"Security"}
						size={"full"}
						position={"relative"}
						options={[
							{
								text: "How We Secure Your Data",
								href: "/security/how-we-secure-your-data",
								icon: "shield-check",
								color: "#0051a8"
							},
							{
								text: "Security & Privacy Policy",
								href: "/security/security-and-privacy-policy",
								icon: "fingerprint",
								color: "#5700a8"
							},
							{
								text: "Governance",
								href: "/security/governance",
								icon: "building-lock",
								color: "#f7a501"
							}
						]}
					/>
					<Dropdown
						title={"Responsible AI"}
						size={"full"}
						position={"relative"}
						options={[
							{
								text: "Overview",
								href: "/responsible-ai/overview",
								icon: "microchip-ai",
								color: "#b62ad9"
							},
							{
								text: "Answers You Can Trust",
								href: "/responsible-ai/answers-you-can-trust",
								icon: "message-bot",
								color: "#a80300"
							},
							{
								text: "Data Training & Privacy",
								href: "/responsible-ai/data-training-and-privacy",
								icon: "binary-lock",
								color: "#0051a8"
							},
							{
								text: "Principles & Approach",
								href: "/responsible-ai/principles-and-approach",
								icon: "brain-circuit",
								color: "#00a857"
							}
						]}
					/>
					<Dropdown
						title={"Company"}
						size={"full"}
						position={"relative"}
						options={[
							{
								text: "Contact Us",
								href: "/get-started",
								icon: "building-user",
								color: "#0051a8"
							}
						]}
					/>
					<div className="get-started">
						<Link href={"/get-started"}>Get Started</Link>
					</div>
				</Collapsible>
			</div>
		);
	}
}
