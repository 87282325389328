import { Button } from "../Button";
import { Col } from "../Col";
import { FaIcon } from "../FaIcon";
import { Row } from "../Row";
import { P } from "../Text";
import { ViewTransition } from "../ViewTransition";
import "./PricingGrid.scss";

export function PricingGrid() {
	return (
		<ViewTransition full>
			<div className="__sage-pricing-grid">
				<div className="option orange">
					<div className="header orange">
						<Col gap="2rem">
							<div className="title">
								<P>Instant Trial</P>
							</div>
							<div className="subtitle">
								<P>Experience enterprise-grade AI instantly without any setup or commitment</P>
							</div>
						</Col>
						<div className="action">
							<Button
								variant={"dark"}
								href="https://sageai.dev/app/trial"
							>
								<Row
									verticalAlign="center"
									horizontalAlign="center"
								>
									Start Trial Now
									<FaIcon
										icon={"unlock"}
										color={"#f7a501"}
										padding="0"
										minWidth="0"
										paddingInline="0.5rem"
										hideBg
									/>
								</Row>
							</Button>
						</div>
					</div>
					<div className="divider" />
					<div className="details">
						<Col gap="1rem">
							<P>
								<Row
									verticalAlign="center"
									gap={"1rem"}
								>
									<FaIcon
										padding={"0"}
										icon="check"
										color="#0353a9"
										minWidth="2rem"
										hideBg
									/>
									Secure Sandbox Environment
								</Row>
							</P>
							<P>
								<Row
									verticalAlign="center"
									gap={"1rem"}
								>
									<FaIcon
										padding={"0"}
										icon="check"
										color="#0353a9"
										minWidth="2rem"
										hideBg
									/>
									One Full CIM Project
								</Row>
							</P>
							<P>
								<Row
									verticalAlign="center"
									gap={"1rem"}
								>
									<FaIcon
										padding={"0"}
										icon="check"
										color="#0353a9"
										minWidth="2rem"
										hideBg
									/>
									Unlimited Document Uploads
								</Row>
							</P>
							<P>
								<Row
									verticalAlign="center"
									gap={"1rem"}
								>
									<FaIcon
										padding={"0"}
										icon="check"
										color="#0353a9"
										minWidth="2rem"
										hideBg
									/>
									Full AI Capabilities
								</Row>
							</P>
							<P>
								<Row
									verticalAlign="center"
									gap={"1rem"}
								>
									<FaIcon
										padding={"0"}
										icon="check"
										color="#0353a9"
										minWidth="2rem"
										hideBg
									/>
									No Login Required
								</Row>
							</P>
						</Col>
					</div>
				</div>
				<div className="option green">
					<div className="header green">
						<Col gap="2rem">
							<div className="title">
								<P>Full Access</P>
							</div>
							<div className="subtitle">
								<P>Get immediate access to the complete platform for your entire team</P>
							</div>
						</Col>
						<div className="action">
							<Button
								variant={"dark"}
								href="https://buy.stripe.com/5kA9EvcTbdJG36MeV0"
							>
								<Row
									verticalAlign="center"
									horizontalAlign="center"
								>
									Sign Up Now
									<FaIcon
										icon={"star"}
										color={"#ace3c8"}
										padding="0"
										minWidth="0"
										paddingInline="0.5rem"
										hideBg
									/>
								</Row>
							</Button>
						</div>
					</div>
					<div className="divider" />
					<div className="details">
						<Col gap="1rem">
							<P>
								<Row
									verticalAlign="center"
									gap={"1rem"}
								>
									<FaIcon
										padding={"0"}
										icon="check"
										color="#0353a9"
										minWidth="2rem"
										hideBg
									/>
									Unlimited CIMs and Deal Documents
								</Row>
							</P>
							<P>
								<Row
									verticalAlign="center"
									gap={"1rem"}
								>
									<FaIcon
										padding={"0"}
										icon="check"
										color="#0353a9"
										minWidth="2rem"
										hideBg
									/>
									Team Collaboration Tools
								</Row>
							</P>
							<P>
								<Row
									verticalAlign="center"
									gap={"1rem"}
								>
									<FaIcon
										padding={"0"}
										icon="check"
										color="#0353a9"
										minWidth="2rem"
										hideBg
									/>
									Custom Workflows
								</Row>
							</P>
							<P>
								<Row
									verticalAlign="center"
									gap={"1rem"}
								>
									<FaIcon
										padding={"0"}
										icon="check"
										color="#0353a9"
										minWidth="2rem"
										hideBg
									/>
									Deal Management
								</Row>
							</P>
							<P>
								<Row
									verticalAlign="center"
									gap={"1rem"}
								>
									<FaIcon
										padding={"0"}
										icon="check"
										color="#0353a9"
										minWidth="2rem"
										hideBg
									/>
									Enterprise Security
								</Row>
							</P>
						</Col>
					</div>
				</div>
				<div className="option blue">
					<div className="header blue">
						<Col gap="2rem">
							<div className="title">
								<P>Talk With Sales</P>
							</div>
							<div className="subtitle">
								<P>Schedule time to see a detailed walkthrough and discuss your firm's specific needs</P>
							</div>
						</Col>
						<div className="action">
							<Button
								variant={"dark"}
								href="#calendar"
							>
								<Row
									verticalAlign="center"
									horizontalAlign="center"
								>
									Schedule a Call
									<FaIcon
										icon={"calendar"}
										color={"#1661b0"}
										padding="0"
										minWidth="0"
										paddingInline="0.5rem"
										hideBg
									/>
								</Row>
							</Button>
						</div>
					</div>
					<div className="divider" />
					<div className="details">
						<Col gap="1rem">
							<P>
								<Row
									verticalAlign="center"
									gap={"1rem"}
								>
									<FaIcon
										padding={"0"}
										icon="check"
										color="#0353a9"
										minWidth="2rem"
										hideBg
									/>
									Live Product Demonstration
								</Row>
							</P>
							<P>
								<Row
									verticalAlign="center"
									gap={"1rem"}
								>
									<FaIcon
										padding={"0"}
										icon="check"
										color="#0353a9"
										minWidth="2rem"
										hideBg
									/>
									Security & Compliance Review
								</Row>
							</P>
							<P>
								<Row
									verticalAlign="center"
									gap={"1rem"}
								>
									<FaIcon
										padding={"0"}
										icon="check"
										color="#0353a9"
										minWidth="2rem"
										hideBg
									/>
									Technical Deep Dive
								</Row>
							</P>
							<P>
								<Row
									verticalAlign="center"
									gap={"1rem"}
								>
									<FaIcon
										padding={"0"}
										icon="check"
										color="#0353a9"
										minWidth="2rem"
										hideBg
									/>
									Custom Use Case Planning
								</Row>
							</P>
							<P>
								<Row
									verticalAlign="center"
									gap={"1rem"}
								>
									<FaIcon
										padding={"0"}
										icon="check"
										color="#0353a9"
										minWidth="2rem"
										hideBg
									/>
									Team Training
								</Row>
							</P>
						</Col>
					</div>
				</div>
			</div>
		</ViewTransition>
	);
}
